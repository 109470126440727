import { Suspense, useEffect, useState } from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  getBoletoData,
  getCashOutRoutesData,
  getVertical,
  Hooks,
  IdentityConfirmationDialog,
  setBoletoData,
  setHeader,
  setTokenData,
  useAccountStore,
  Types as UTypes,
  validateIdentity,
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Button, Input } from "@hyperlocal/vital";
import Numeral from "numeral";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import PaymentDate from "../../../components/Payment/PaymentDate";
import PaymentValue from "../../../components/Payment/PaymentValue";
import * as Styles from "./styles";
import * as Types from "./types";
import "numeral/locales/pt-br";
import { Sheet, toast } from "@hyperlocal/vital2";
import { isToday, parseISO } from "date-fns";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../../components/loading/loading";
import { BoletoPaymentInfo } from "../../../components/Payment/BoletoPaymentInfo";
import { PendingPaymentReceipt } from "../../../components/pending-payment-receipt";
import { ScheduledPaymentReceipt } from "../../../components/scheduled-payment-receipt/scheduled-payment-receipt";
import { usePayment } from "../../../context/payment";
import { useFetchState } from "../../../hooks";
import { CreatePaymentData } from "../../../services/types/create-payment";
import {
  getMaxPaymentAmount,
  getMinPaymentAmount,
  getPaymentData,
  isFixedValue,
} from "../../../utils/payment-utils";
import { MFAValidation } from "../../MFA-validation";

Numeral.locale("pt-br");

type BoletoPaymentProps = {
  resetBoleto?: () => void;
};

export function BoletoPayment({ resetBoleto }: Readonly<BoletoPaymentProps>) {
  const isMobile = Hooks.useMediaQuery("mobile");
  const { currentAccountId } = useAccountStore();
  const routes = getCashOutRoutesData();
  const { setCreatePaymentData } = usePayment();
  const [fetchState, updateFetchState] = useFetchState();
  const [payment, setPayment] = useState<UTypes["IBoleto"]>(
    getPaymentData(getBoletoData()),
  );
  const [drawer, setDrawer] = useState<Types.IDrawer>({ state: false });
  const isFixed = isFixedValue(payment);
  const [description, setDescription] = useState<string>("");
  const [isIdentityValidationDialogOpen, setIsIdentityValidationDialogOpen] =
    useState(false);

  const navigate = useNavigate();

  const handleDateChange = (date: string) => {
    setBoletoData({
      ...getBoletoData(),
      scheduledDate: date,
    });

    updateFetchState({
      errorStatus: false,
      errorMessage: "",
    });
  };

  const handlePaymentValueRules = ({
    payment,
    newAmount,
  }: {
    payment: UTypes["IBoleto"];
    newAmount: number;
  }) => {
    const maxPaymentAmount = getMaxPaymentAmount(payment);
    const minPaymentAmount = getMinPaymentAmount(payment);

    const rulesCodeMap = {
      1: () =>
        updateFetchState({
          errorStatus: false,
          errorMessage: "",
        }),
      2: () => {
        if (maxPaymentAmount !== undefined && maxPaymentAmount < newAmount) {
          updateFetchState({
            errorStatus: true,
            errorMessage: "Valor maior que o máximo permitido",
          });
          return;
        }

        if (minPaymentAmount !== undefined && minPaymentAmount > newAmount) {
          updateFetchState({
            errorStatus: true,
            errorMessage: "Valor menor que o mínimo permitido",
          });
          return;
        }

        updateFetchState({
          errorStatus: false,
          errorMessage: "",
        });
      },
      4: () => {
        if (minPaymentAmount !== undefined && minPaymentAmount > newAmount) {
          updateFetchState({
            errorStatus: true,
            errorMessage: "Valor menor que o mínimo permitido",
          });
          return;
        }
        updateFetchState({
          errorStatus: false,
          errorMessage: "",
        });
      },
    };

    return rulesCodeMap[payment.receivingDivergentValueCode];
  };

  const handleInputChange = (newAmount: number) => {
    if (!isFixedValue(payment)) {
      handlePaymentValueRules({ payment, newAmount });
    }

    setPayment({
      ...payment,
      amount: newAmount.toString(),
    });
  };

  const openReceiptDrawer = (receiptId: string) => {
    setDrawer({
      state: true,
      content: (
        // @ts-ignore
        <Parcel
          // @ts-ignore
          config={() => System.import("@hyperlocal/banking-receipt")}
          mountParcel={mountRootParcel}
          receiptId={receiptId}
          receiptType="Payment"
          movement="OUT"
        />
      ),
      title: "Comprovante",
    });
  };

  const openPendingReceiptDrawer = () => {
    setDrawer({
      state: true,
      title: "Detalhes do pagamento",
      content: (
        <PendingPaymentReceipt
          value={payment?.grossValue || 0}
          barcode={payment?.digitableLine || ""}
        />
      ),
    });
  };

  const openScheduledReceiptDrawer = (id: string) => {
    setDrawer({
      state: true,
      content: (
        <Suspense
          fallback={
            <div className="flex items-center justify-center">
              <Loading />
            </div>
          }
        >
          <ScheduledPaymentReceipt id={id} />
        </Suspense>
      ),
      title: "Comprovante",
    });
  };

  const openMFA = (cafToken: string) => {
    setDrawer({
      state: true,
      content: (
        <MFAValidation
          onPendingReceipt={openPendingReceiptDrawer}
          cafToken={cafToken}
          onSuccess={(id, isScheduled) => {
            if (isScheduled) {
              return openScheduledReceiptDrawer(id);
            }

            if (!isScheduled) {
              openReceiptDrawer(id);
            }
          }}
        />
      ),
      title: "Validação",
    });
  };

  const formatCreatePaymentData = async () => {
    const {
      message,
      digitableLine,
      dueDate,
      beneficiaryName,
      beneficiaryDocument,
      discount,
      calculatedInterestAmount,
      calculatedFineAmount,
      grossValue,
      scheduledDate,
    } = getBoletoData();

    const isScheduled = !isToday(parseISO(scheduledDate));

    const formattedData: CreatePaymentData = {
      idAccount: currentAccountId,
      description: message ?? "Pagamento",
      barCodeNumber: digitableLine ?? "",
      dueDate: dueDate ?? "",
      assignor: beneficiaryName ?? "",
      assignorDocument: beneficiaryDocument ?? "",
      discount: parseFloat(discount ?? "0"),
      interest: parseFloat(calculatedInterestAmount ?? "0"),
      fine: parseFloat(calculatedFineAmount ?? "0"),
      amount: parseFloat(grossValue.toString() ?? "0"),
      vertical: getVertical(),

      ...(isScheduled && {
        scheduledDate,
      }),

      ...(!isScheduled && {
        scheduleNextWindow: false,
      }),
    };

    return formattedData;
  };

  const createPayment = async () => {
    setTokenData(undefined);

    try {
      const createPaymentData = await formatCreatePaymentData();
      setCreatePaymentData(createPaymentData);

      await validateIdentity({
        onSuccess: async (response) => {
          openMFA(response.attestation);
        },
        onFailure: (errorMessage) => {
          if (errorMessage === "Resource not found") {
            setIsIdentityValidationDialogOpen(true);
          } else {
            toast({
              title: "Erro",
              description: errorMessage,
              variant: "error",
              position: "top-right",
            });
          }
        },
      });
    } catch (error) {
      const message = JSON.parse(error?.response?.data).message;

      updateFetchState({
        errorStatus: true,
        errorMessage: message,
      });
    } finally {
      updateFetchState({ isFetching: false });
    }
  };

  const checkReceiptionHour = (start: string, end: string) => {
    const { scheduledDate } = getBoletoData();

    let scheduled;

    const actualDate = new Date();

    if (scheduledDate) {
      scheduled = parseISO(scheduledDate);
    } else {
      scheduled = new Date();
    }

    // 0 = Domingo
    // 1 = Segunda
    // ...
    // 5 = Sexta
    // 6 = Sábado
    if ([0, 6].includes(scheduled.getDay())) {
      return false;
    }

    if (scheduled > actualDate) return true;

    const actualHour = `${actualDate.getHours()}`.padStart(2, "0");
    const actualMinute = `${actualDate.getMinutes()}`.padStart(2, "0");

    const actualTime = `${actualHour}:${actualMinute}`;

    return actualTime <= end;
  };

  // Entra nessa função se for Sábado ou Domingo ou se PASSOU do horário máximo do boleto
  const scheduleToNextDay = async () => {
    const { scheduledDate } = getBoletoData();
    const date = new Date(scheduledDate);
    // Mínimo é 1 pois scheduledDate pode ser a data de "Hoje" que passou do horário
    let skipCount = 1;

    if (date.getDay() === 4) skipCount = 3;
    else if (date.getDay() === 5) skipCount = 2;

    date.setDate(date.getDate() + skipCount);

    setBoletoData({
      ...getBoletoData(),
      scheduledDate: date.toISOString(),
    });

    await createPayment();
  };

  const payButtonClick = async () => {
    const { hourReceptionStart, hourReceptionEnd } = getBoletoData();

    if (checkReceiptionHour(hourReceptionStart, hourReceptionEnd)) {
      await createPayment();
    } else {
      setDrawer({
        state: true,
        content: (
          <>
            <p>
              Estamos fora do expediente bancário. Você pode agendar o pagamento
              do boleto para o próximo dia útil.
            </p>

            <Button onClick={scheduleToNextDay}>Agendar</Button>
          </>
        ),
        title: "Fora do expediente bancário",
      });

      updateFetchState(undefined);
    }
  };

  const handleCloseDrawer = () => {
    const shouldResetBoleto = ["Comprovante", "Detalhes do pagamento"].includes(
      drawer?.title,
    );
    if (shouldResetBoleto && resetBoleto) {
      resetBoleto();
    }

    setDrawer({ ...drawer.content, state: false });
  };

  const onDateWarnClick = () => {
    const content = (
      <p>{`• Algumas categorias de boletos possuem horário máximo para pagamento, caso esteja fora do horário agende para o próximo dia útil. 

    • Podem ocorrer atrasos devido ao banco pagador.
        
    • Confirme as informações digitadas para evitar erros de pagamento.
        
    • Não será aceito pagamento de boleto em situação de acordo/desconto.
        
    • Vencimentos aos sábados, domingos e feriados serão aceitos somente com o agendamento até o próximo dia útil.`}</p>
    );
    updateFetchState(undefined);

    setDrawer({
      state: true,
      content,
      title: "Horário para pagamento",
    });
  };

  const isPaidEnabled = Number(payment.amount) > 0 && !fetchState.errorStatus;

  useEffect(() => {
    window.addEventListener("@hyperlocal-token-validation/close-drawer", () => {
      setDrawer((prev) => ({ ...prev, state: false }));
    });
  }, []);

  useEffect(() => {
    setHeader({
      leftIcon: {
        name: "ArrowArrowNoLineLeft",
        onClick: () => navigate(routes.dashboard),
      },
      title: "Pagamento",
    });
  }, [navigate, routes.dashboard]);

  return (
    <>
      {isMobile && (
        // @ts-ignore
        <Parcel // @ts-ignore
          config={() => System.import("@hyperlocal/banking-balance")}
          mountParcel={mountRootParcel}
        />
      )}
      <Styles.Container>
        <PaymentValue
          amount={Number(payment.grossValue)}
          isFixed={isFixed}
          emitInputChange={handleInputChange}
          helper={fetchState.errorStatus ? "error" : "info"}
          helperText={fetchState.errorStatus ? fetchState.errorMessage : ""}
        />
        <PaymentDate
          paymentType="BOLETO"
          onAlertClick={() => onDateWarnClick()}
          emitDateChange={handleDateChange}
        />
        <Input
          className="internalID"
          type={"text"}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          label="Identificação interna"
          placeholder="Digite aqui (Opcional)"
          maxLength={60}
        />
        {isMobile && payment.amount && <BoletoPaymentInfo payment={payment} />}
        <Button
          disabled={!isPaidEnabled}
          onClick={() => payButtonClick()}
          isLoading={fetchState.isFetching}
        >
          Pagar
        </Button>
      </Styles.Container>

      <IdentityConfirmationDialog
        isOpen={isIdentityValidationDialogOpen}
        onClose={setIsIdentityValidationDialogOpen}
      />

      <Sheet.Root
        open={drawer.state}
        onOpenChange={(open) => {
          setDrawer({ state: open });

          if (!open) {
            handleCloseDrawer();
          }
        }}
      >
        <Sheet.Content
          className={drawer.title === "Comprovante" ? "!p-0" : ""}
          side={isMobile ? "bottom" : "right"}
        >
          {drawer.title !== "Comprovante" && (
            <Sheet.Header>
              <Sheet.Title>{drawer.title}</Sheet.Title>
            </Sheet.Header>
          )}

          {drawer.content}
        </Sheet.Content>
      </Sheet.Root>
    </>
  );
}

export default BoletoPayment;
