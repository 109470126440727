// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { getBoletoData, Types as UTypes } from "@hyperlocal/banking-utility";

export function validateIsFixed(payment: UTypes["IBoleto"]) {
  const maxAmount =
    Number(payment.maxPaymentAmount) || Number(payment.maxAmount);
  const minAmount =
    Number(payment.minPaymentAmount) || Number(payment.minAmount);

  return maxAmount > 0 && maxAmount !== minAmount;
}

export function getPaymentData(data: ReturnType<typeof getBoletoData>) {
  return {
    ...data,
    amount: data.fineValue,
    dueDate: data.dueDate,
  };
}

export function isFixedValue(data: ReturnType<typeof getBoletoData>) {
  return [0, 3].includes(data.receivingDivergentValueCode);
}

export const getMaxPaymentAmount = (
  payment: UTypes["IBoleto"],
): number | undefined => {
  return Number(payment.maxPaymentAmount) || Number(payment.maxAmount);
};

export const getMinPaymentAmount = (
  payment: UTypes["IBoleto"],
): number | undefined => {
  return Number(payment.minPaymentAmount) || Number(payment.minAmount);
};
