// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Types } from "@hyperlocal/banking-utility";
import { ValidateBarcodeResponse } from "../../services/types/validate-barcode";

/*

ReceivingDivergentValue
objeto
Receiving information.

Código
inteiro
If the payment slip is "Normal" it will be '0'. If it's a NPC it can be '1', '2', '3', '4'.

>= 0
<= 4
Descrição
Describes each one of the following codes: 0 = The payment slip is "Normal"; 1 = "Qualquer valor"; 2 = "Entre o mínimo e o máximo"; 3 = "Não aceitar pagamento com valor divergente"; 4 = "The payment slip can be paid with the minimum value.".
*/

// TODO: remover isso e utilizar o retorno nativo do back, QUANDO houver tempo.

const sanitizeNPC = (rawValidateBarCode: ValidateBarcodeResponse) => {
  const {
    barcode,
    result: {
      paymentInfoNPC,
      wasPaid,
      paymentSchedulingDate,
      billPaymentTypeDescription,
    },
  } = rawValidateBarCode;

  const {
    billValue,
    dueDate,
    paymentLimitDate,
    nextUtilDay,
    traders,
    computedBillValues,
    receiptRules,
    openingPaymentschedule,
    closingPaymentschedule,
    isValidDate,
    receivingDivergentValue,
  } = paymentInfoNPC;

  const indAllowsChangeValue =
    receivingDivergentValue.description.includes("Qualquer valor");

  const grossValue =
    computedBillValues.totalAmountToCharge > 0
      ? computedBillValues.totalAmountToCharge
      : billValue;

  return {
    amount: String(billValue),
    digitableLine: barcode,
    scheduledDate: wasPaid ? undefined : paymentSchedulingDate,
    beneficiaryName: traders.recipient,
    beneficiaryDocument: traders.recipientDocument,
    chargedName: traders.recipient,
    chargedDocument: traders.payerDocument,
    discount: String(computedBillValues.discountValueCalculated),
    dueDate,
    fineValue: String(billValue),
    serviceType: billPaymentTypeDescription,
    deadlinePayment: paymentLimitDate,
    dataNextDayUtil: nextUtilDay,
    maxAmount: String(computedBillValues.totalAmountToCharge),
    calculatedInterestAmount: String(
      computedBillValues.calculatedInterestAmount,
    ),
    maxPaymentAmount: String(computedBillValues.totalAmountToCharge),
    minPaymentAmount: String(receiptRules.minPaymentValue),
    calculatedFineAmount: String(computedBillValues.calculatedFineValue),
    amountUpdatedPayment: String(computedBillValues.paymentAmountUpdated),
    hourReceptionStart: openingPaymentschedule,
    hourReceptionEnd: closingPaymentschedule,
    indNextSettlement: isValidDate ? "true" : "false",
    indAllowsChangeValue: indAllowsChangeValue ? "true" : "false",
    grossValue,
    receivingDivergentValueCode: receivingDivergentValue.code,
  };
};

const sanitizeNormal = (rawValidateBarCode: ValidateBarcodeResponse) => {
  const {
    barcode,
    result: {
      validateBarCode,
      wasPaid,
      paymentSchedulingDate,
      billPaymentTypeDescription,
      paymentInfoNPC,
    },
  } = rawValidateBarCode;

  const {
    value,
    dueDate,
    minTime,
    maxTime,
    hasDueDate,
    currentDate,
    description,
  } = validateBarCode;

  const { receivingDivergentValue } = paymentInfoNPC;

  return {
    amount: String(value),
    digitableLine: barcode,
    scheduledDate: wasPaid ? undefined : paymentSchedulingDate,
    beneficiaryName: "N/A", // TODO: Rever
    beneficiaryDocument: "N/A", // TODO: Rever
    chargedName: description,
    chargedDocument: "N/A", // TODO: Rever
    discount: "0", // TODO: Rever
    dueDate,
    fineValue: String(value),
    serviceType: billPaymentTypeDescription,
    deadlinePayment: dueDate, // TODO: Rever
    dataNextDayUtil: currentDate, // TODO: Rever
    maxAmount: String(value), // TODO: Rever
    calculatedInterestAmount: "0", // TODO: Rever
    maxPaymentAmount: "N/A", // TODO: Rever
    minPaymentAmount: "N/A", // TODO: Rever
    calculatedFineAmount: "0", // TODO: Rever
    amountUpdatedPayment: String(value), // TODO: Rever
    hourReceptionStart: minTime,
    hourReceptionEnd: maxTime,
    indNextSettlement: hasDueDate ? "true" : "false",
    indAllowsChangeValue: "false",
    grossValue: value,
    receivingDivergentValueCode: receivingDivergentValue.code,
  };
};

export function sanitizeValidateBarCode(
  rawValidateBarCode: ValidateBarcodeResponse,
): Types["IBoleto"] {
  if (rawValidateBarCode.result.billPaymentTypeDescription === "Normal") {
    return sanitizeNormal(rawValidateBarCode);
  }

  return sanitizeNPC(rawValidateBarCode);
}
