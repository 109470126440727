import { FC, useEffect, useState } from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { Types as UTypes } from "@hyperlocal/banking-utility";
import Numeral from "numeral";
import * as S from "./styles";
import * as Types from "./types";
import "numeral/locales/pt-br";
import { isValidCPF } from "@brazilian-utils/brazilian-utils";

Numeral.locale("pt-br");

export const BoletoPaymentInfo: FC<Types.IPaymentData> = ({ payment }) => {
  const [boletoData, setBoletoData] = useState<UTypes["IBoleto"]>({});

  const handlePaymentDictionary = (serviceType: string) => {
    const commonFields = {
      digitableLine: "Código de barras",
      chargedName: "Beneficiário",
    };

    const dictionary = {
      NPC: {
        ...commonFields,
        issuerName: "Instituição",
        dueDate: "Vencimento",
        calculatedFineAmount: "Multa",
        calculatedInterestAmount: "Juros",
        discount: "Desconto",
        grossValue: "Valor do documento",
      },

      Normal: {
        ...commonFields,
        grossValue: "Valor do documento",
      },
    };

    const defaultDictionary = dictionary.Normal;
    return dictionary[serviceType] || defaultDictionary;
  };

  const formatCurrency = (amount: number) => {
    return "R$ " + Numeral(amount).format(" ,000,000.00");
  };

  const formatDocument = (document: string) => {
    const documentType = isValidCPF(document) ? "CPF" : "CNPJ";

    const hiddenDocument = `${document.slice(0, 3)}*****${document.slice(-2)}`;

    return { documentType, hiddenDocument };
  };

  const dateIsValid = (date) => {
    return date instanceof Date && isFinite(date.getTime());
  };

  const formatPaymentDate = (paymentDate: string) => {
    const date = new Date(paymentDate);

    if (!dateIsValid(date)) return paymentDate;

    date.setDate(date.getDate() + 1);

    return date.toLocaleDateString("pt-BR");
  };

  const formatPayment = (payment: UTypes["IBoleto"]) => {
    const paymentDate = formatPaymentDate(payment.dueDate);

    let chargedDocument = "";

    if (payment.serviceType === "FichaCompensacao") {
      const { documentType, hiddenDocument } = formatDocument(
        payment.chargedDocument,
      );

      chargedDocument = `${documentType} ${hiddenDocument}`;
    }

    setBoletoData({
      ...payment,
      chargedDocument,
      dueDate: paymentDate,
    });
  };

  useEffect(() => {
    setBoletoData(payment);
    formatPayment(payment);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.Container>
      <S.Title>Dados do pagamento</S.Title>
      {Object.keys(handlePaymentDictionary(payment.serviceType)).map(
        (key, index) =>
          // ? Renderiza apenas se fizer parte do dicionario
          // ? Renderiza em ordem conforme posição no dicionario
          boletoData[key] !== undefined && (
            <S.DataList key={index}>
              <S.DataTitle>
                {handlePaymentDictionary(payment.serviceType)[key]}
              </S.DataTitle>

              <S.DataDescription className={key === "grossValue" && "amount"}>
                {isNaN(boletoData[key]) || key === "digitableLine"
                  ? boletoData[key]
                  : formatCurrency(Number(boletoData[key]))}
              </S.DataDescription>
            </S.DataList>
          ),
      )}
    </S.Container>
  );
};

export default BoletoPaymentInfo;
