import { useState } from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-ignore
import { setReceipt } from "@hyperlocal/banking-utility";
import { Button, TextField, toast } from "@hyperlocal/vital2";
import { withMask } from "use-mask-input";
import { usePayment } from "../../context/payment";
import apis from "../../services";

const CODE_LENGTH = 6;

type MFAValidationProps = {
  onSuccess: (id: string, isScheduled?: boolean) => void;
  cafToken: string;
  onPendingReceipt: () => void;
};

const PENDING_RESPONSE = "O seu lançamento está pendente de aprovação";

export function MFAValidation({
  onSuccess,
  cafToken,
  onPendingReceipt,
}: Readonly<MFAValidationProps>) {
  const [code, setCode] = useState("");

  const {
    createPaymentData: { scheduledDate, ...payload },
  } = usePayment();

  const handleConfirm = async () => {
    try {
      if (scheduledDate) {
        const { data } = await apis.createSchedulePayment({
          payload,
          mfa: code,
          dateSchedule: scheduledDate,
          cafToken,
        });

        setReceipt({
          type: "payment_slip",
          beneficiaryName: data.assignor,
          value: data.amount,
        });

        onSuccess(data.idSchedule, true);
        return;
      }

      const { data } = await apis.createPayment({
        ...payload,
        mfa: code,
        cafToken,
      });

      setReceipt({
        type: "payment_slip",
        beneficiaryName: data.assignor,
        value: data.amount,
      });

      onSuccess(data.transactionCode);
    } catch (error) {
      const message = error?.response?.data;

      if (!message || (typeof message !== "string" && !Array.isArray(message)))
        return toast({
          title: "Não foi possível finalizar o pagamento.",
          variant: "error",
        });

      if (message[0] === PENDING_RESPONSE) return onPendingReceipt();

      toast({
        title: "Não foi possível finalizar o pagamento.",
        description: message[0] || message,
        variant: "error",
      });
    }
  };

  return (
    <div className="flex h-full max-w-[430px] flex-col">
      <h5 className="my-4 font-base text-sm/md font-bold text-neutral-darkest">
        Informe o código de autenticação
      </h5>
      <div className="flex gap-2">
        <span>●</span>
        <span>
          Abra seu aplicativo Google Authenticator e insira o código de seis
          dígitos exibido no aplicativo.
        </span>
      </div>
      <TextField.Label htmlFor="totp" className="mt-inset-md">
        Digite o código
      </TextField.Label>
      <TextField.Root>
        <TextField.Input
          id="totp"
          type="text"
          ref={withMask("999999")}
          value={code}
          onChange={({ target }) => setCode(target.value)}
        />
      </TextField.Root>
      <Button.Root
        className="mt-auto mobile:mt-4"
        disabled={code.length !== CODE_LENGTH}
        onClick={handleConfirm}
      >
        Verificar
      </Button.Root>
    </div>
  );
}
