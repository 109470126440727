/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from "react";
import {
  appVersionQueryFactory,
  CASH_OUT_CDN,
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { useQuery, UseQueryResult } from "@tanstack/react-query";

export function useCheckAppVersion() {
  const [route, setRoute] = useState(window.location.pathname);

  useEffect(() => {
    const handleRouteChange = () => {
      setRoute(window.location.pathname);
    };
    window.addEventListener("popstate", handleRouteChange);
    return () => {
      window.removeEventListener("popstate", handleRouteChange);
    };
  }, []);

  const queryOptions = appVersionQueryFactory.check({
    url: CASH_OUT_CDN,
    route,
    storageKey: "CASHOUT_VERSION",
  }) as any;

  return useQuery({
    ...queryOptions,
  });
}
