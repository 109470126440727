import { useEffect } from "react";
import {
  CashOutRoutes,
  getDashboardRoutesData,
  ProtectedRoute,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Route, Routes as Switch, useNavigate } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { useCheckAppVersion } from "../api/use-check-app-version";
import { useRoutes } from "../context/routes";
import { Boleto } from "../pages/Boleto";
import BoletoCopyPaste from "../pages/Boleto/CopyPaste";
import BoletoPayment from "../pages/Boleto/Payment";
import Pix from "../pages/Pix";
import PixCopyPaste from "../pages/Pix/CopyPaste";
import PixPayment from "../pages/Pix/Payment";

type RoutesProps = {
  defaultRoutes: CashOutRoutes;
};

export const Routes = (props: RoutesProps) => {
  const { defaultRoutes } = props;
  const { setRoutes } = useRoutes();

  const navigate = useNavigate();

  const {
    cashOutPix,
    cashOutPixCopiaCola,
    cashOutPixPayment,
    cashOutBoleto,
    cashOutBoletoCopiaCola,
    cashOutBoletoPayment,
  } = defaultRoutes;

  const handleResetBoleto = () => {
    navigate(defaultRoutes.cashOutBoletoCopiaCola);
  };

  useCheckAppVersion();

  useEffect(() => {
    setRoutes(defaultRoutes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pixRoutes = [
    {
      path: cashOutPix,
      element: Pix,
    },
    {
      path: cashOutPixCopiaCola,
      element: PixCopyPaste,
    },
    {
      path: cashOutPixPayment,
      element: PixPayment,
    },
  ];

  const boletoRoutes = [
    {
      path: cashOutBoleto,
      element: Boleto,
    },
    {
      path: cashOutBoletoCopiaCola,
      element: BoletoCopyPaste,
    },
    {
      path: cashOutBoletoPayment,
      element: BoletoPayment,
      props: {
        resetBoleto: handleResetBoleto,
      },
    },
  ];

  return (
    <Switch>
      {pixRoutes.map(({ element: Element, path }) => (
        <Route
          path={path}
          element={
            <ProtectedRoute
              allowedRoles={[
                "Administrador",
                "Analista",
                "Financeiro",
                "Moderador",
                "Proprietário",
              ]}
            >
              <Element />
            </ProtectedRoute>
          }
          key={path}
        />
      ))}

      {boletoRoutes.map(({ element: Element, path, props }) => (
        <Route
          path={path}
          element={
            <ProtectedRoute
              allowedRoles={[
                "Proprietário",
                "Administrador",
                "Moderador",
                "Financeiro",
              ]}
            >
              <Element {...props} />
            </ProtectedRoute>
          }
          key={path}
        />
      ))}

      <Route
        path={getDashboardRoutesData().pixDashboard}
        element={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Parcel
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            config={() => System.import("@hyperlocal/banking-pix")}
            mountParcel={mountRootParcel}
          />
        }
      />
      <Route
        path={getDashboardRoutesData().statements}
        element={
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Parcel
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            config={() => System.import("@hyperlocal/banking-statements")}
            mountParcel={mountRootParcel}
          />
        }
      />
    </Switch>
  );
};
