import { useCallback, useEffect, useState } from "react";
/* eslint-disable @typescript-eslint/ban-ts-comment */
import {
  API,
  getCashOutRoutesData,
  Hooks,
  ProtectedComponent,
  setHeader,
  // @ts-ignore
} from "@hyperlocal/banking-utility";
import { Breadcrumb, BreadCrumbItem, Button, Input } from "@hyperlocal/vital";
import { Link, useNavigate } from "react-router-dom";
import { mountRootParcel } from "single-spa";
import Parcel from "single-spa-react/parcel";
import { ArrowLeft } from "../../../assets/svgs";
import { useClipboard } from "../../../hooks/useClipboard";
import { checkPixCode } from "../../../utils/pix";
import PixPayment from "../Payment";
import * as S from "./styles";

type PixDecodeType =
  | { type: "STATIC_QRCODE" }
  | { type: "DYNAMIC_QRCODE"; allowChange: boolean };

export type PixDecoded = {
  idEndToEnd: string;
  idTx: string;
  city: string;
  payee: {
    ispb: number;
    bankName: string;
    bankAccountNumber: string;
    bankBranchNumber: string;
    bankAccountType: string;
    document: string;
    name: string;
    key: string;
  };
  details: Array<{
    title: string;
    content: string;
  }>;
  amount: {
    original: number;
  };
} & PixDecodeType;

export function PixCopyPaste() {
  const routes = getCashOutRoutesData();

  const isMobile = Hooks.useMediaQuery("mobile");

  const navigate = useNavigate();

  const { handleGetClipboardResult } = useClipboard();

  const [clipboard, setClipboard] = useState<string>("");
  const [inputValue, setInputValue] = useState<string>("");
  const [error, setError] = useState<boolean>(false);
  const [pix, setPix] = useState<PixDecoded>();

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const pastePixCode = () => {
    setInputValue(clipboard);
  };

  const handleResetPixData = useCallback(() => {
    setPix(null);
    setInputValue("");
  }, []);

  const goToPayment = async () => {
    try {
      const response = await API.pix.post<PixDecoded>(
        "/api/v1/pix/QrCode/decode",
        {
          codeContent: inputValue,
        },
      );

      setError(false);

      if (isMobile) {
        navigate(routes.cashOutPixPayment, { state: { pix: response.data } });
      } else {
        setPix(response.data);
      }
    } catch (error) {
      console.error(error);
      setError(true);
    }
  };

  const handleClipboardResult = (text: string) => {
    if (!text) return;

    if (checkPixCode(text)) {
      setClipboard(text);
    }
  };

  useEffect(() => {
    setHeader({
      title: "Pix",
      leftIcon: {
        name: "ArrowNoLineLeft",
        onClick: () => navigate(routes.dashboard),
      },
    });
  }, [navigate, routes.dashboard]);

  useEffect(() => {
    handleGetClipboardResult(handleClipboardResult);
  }, []);

  return (
    <S.Outer>
      <S.Container>
        {!isMobile && (
          <>
            <Breadcrumb onClickIcon={() => navigate(routes.dashboard)}>
              <BreadCrumbItem onClick={() => navigate(routes.pixDashboard)}>
                Pix
              </BreadCrumbItem>
              <BreadCrumbItem active>Pix Pagamento</BreadCrumbItem>
            </Breadcrumb>

            <S.PageTitle>
              <Link to={routes?.dashboard}>
                <ArrowLeft />
              </Link>
              Pix Pagamento
            </S.PageTitle>
          </>
        )}
        <S.PaymentWrapper>
          <S.BalanceWrapper>
            {/* @ts-ignore */}
            <Parcel // @ts-ignore
              config={System.import("@hyperlocal/banking-balance")}
              mountParcel={mountRootParcel}
            />

            <S.CopyPasteWrapper>
              {pix ? (
                <PixPayment resetPix={handleResetPixData} pix={pix} />
              ) : (
                <>
                  <Input
                    type="area"
                    label="Pix copia e cola"
                    placeholder="Digite aqui..."
                    value={inputValue}
                    onChange={handleInputChange}
                    helper={error ? "error" : "info"}
                    helperText={error ? "Pix copia e cola inválido" : ""}
                    maxLength={396}
                  />
                  <S.ButtonWrapper>
                    {clipboard && isMobile && (
                      <Button variant="secondary" onClick={pastePixCode}>
                        Colar Código Pix
                      </Button>
                    )}
                    {!isMobile && (
                      <Button
                        variant="link"
                        onClick={() => navigate(routes.cashOutBoletoCopiaCola)}
                      >
                        Pagar com Código de Barras
                      </Button>
                    )}

                    <ProtectedComponent
                      allowedRoles={[
                        "Proprietário",
                        "Administrador",
                        "Moderador",
                        "Financeiro",
                      ]}
                    >
                      <Button
                        disabled={inputValue.length === 0}
                        onClick={() => goToPayment()}
                      >
                        Continuar
                      </Button>
                    </ProtectedComponent>
                  </S.ButtonWrapper>
                </>
              )}
            </S.CopyPasteWrapper>
          </S.BalanceWrapper>
          {!isMobile && (
            <S.ContainerMain>
              <Parcel
                config={() => System.import("@hyperlocal/banking-statements")}
                mountParcel={mountRootParcel}
                wrapStyle={{
                  height: "100%",
                  overflow: "hidden",
                }}
                movement="OUT"
                type={["Pix"]}
              />
            </S.ContainerMain>
          )}
        </S.PaymentWrapper>
      </S.Container>
    </S.Outer>
  );
}

export default PixCopyPaste;
